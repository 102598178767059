/* Mizo Youtube Data Analytics Dashboard */
.toggle-section {
  /* Add styles if needed for container */
  margin: 20px;
}

.toggle-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.toggle-button:hover {
  background-color: #0056b3;
}

.channel-list-container {
  margin-top: 20px;
}

.channel-list {
  list-style-type: none;
  padding: 0;
}

.channel-list li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.channel-list li:last-child {
  border-bottom: none;
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  text-align: center;
}

@media (max-width: 1024px) {
.summary-grid {
  grid-template-columns: repeat(2, 1fr);
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  text-align: center;
}

@media (max-width: 1024px) {
  .summary-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .summary-grid {
    grid-template-columns: 1fr;
  }
}
  grid-template-columns: 1fr;
}

.summary-item {
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white; /* Ensure text is visible on colored backgrounds */
}

.summary-item:nth-child(1) {
  background-color: #3498db; /* Primary Blue */
}

.summary-item:nth-child(2) {
  background-color: #e74c3c; /* Bright Red */
}

.summary-item:nth-child(3) {
  background-color: #2ecc71; /* Soft Green */
}

.summary-item:nth-child(4) {
  background-color: #f39c12; /* Sunshine Yellow */
}

.summary-item strong {
  display: block;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.summary-item p {
  margin: 0;
  font-size: 1.6em;
  font-weight: bold;
}

.table-container {
  display: flex;
  justify-content: space-evenly;
  gap: 20px; /* Space between the tables */
  flex-wrap: wrap; /* Allows tables to stack on smaller screens */
}

.top-10-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.top-10-table thead {
  background-color: #3498db; /* Primary Blue */
  color: white;
}

.top-10-table thead th {
  padding: 12px 15px;
  text-align: left;
}

.top-10-table tbody tr:nth-child(even) {
  background-color: #f4f4f4; /* Light gray for even rows */
}

.top-10-table tbody tr:nth-child(odd) {
  background-color: #fff; /* White for odd rows */
}

.top-10-table tbody td {
  padding: 12px 15px;
  text-align: left;
}

.top-10-table th, .top-10-table td {
  border-bottom: 1px solid #ddd; /* Light border for rows */
}

h3.section-title {
  margin-top: 40px;
  font-size: 1.5em;
  color: #333; /* Dark gray for the header */
}

/* Map section */
.custom-icon {
  background-color: hsl(51, 73%, 49%);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: rgb(145, 11, 163);
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  font-weight: bolder;
}
/* src/MyComponent.css */
.leaflet-circle-marker {
  stroke: #13171d;
  stroke-width: 2px;
}

.coe {
  text-align: center;
}


