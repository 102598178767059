@import "../../../assets/global_variables.scss";

.MainHeadingText {
  font-size: 60px;
  line-height: 1em;
  // font-family: "Source Serif Pro";
  font-family: "DM Serif Display";
  color: $brandColor1;
  
  @media screen and (max-width: $mobile) {
    font-size: 50px;
  }
  
}

.CardHeaderText {
  color: $brandColor1;
  font-size: 21px;
  font-family: "Source Serif Pro";
}


.Paragraph {
  font-size: 17px;
}

.SectionHeader {
  font-family: "DM Serif Display";
  font-size: 45px;
  line-height: 1.1em;
}