@font-face {
  font-family: 'Source Serif Pro';
  src: url('./font_files/SourceSerifPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileron';
  src: url('./font_files/Aileron-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Serif Display';
  src: url('./font_files/DMSerifDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Federo';
  src: url('./font_files/Federo-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trend Sans Five';
  src: url('./font_files/TrendSansW00-Five.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trend Sans One';
  src: url('./font_files/TrendSansW00-One.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BenguiatStd-BookCondensed';
  src: url('./font_files/BenguiatStd-BookCondensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
