@import "../../../assets/global_variables.scss";

.brandLogo {
  height: 55px;
}

.AIText {
  color: $brandColor2;
}

.logoText {
  font-family: "Federo", sans-serif;
  font-weight: 400;
  font-style: normal;
}