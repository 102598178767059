.Modal {
  position: fixed;
  z-index: 200;
  // background: #fff;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .Content {
    z-index: 2;
    position: relative;
    padding: 15px;
    background: #fff;
  }

  &[data-size="sm"] {
    .Content {
      width: calc(100vw - 100px);
      max-width: 500px;
    }
  }

  .BackDrop {
    position: absolute;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.7);
  }
}