$marginReset: (h1, h2, h3, h4, h5, p);

@each $elem in $marginReset {
  #{$elem} {
    margin: unset;
    line-height: 1.4em;
  }
}

button {
  cursor: pointer;
}