@import "../../../../assets/global_variables.scss";

.DisplayGrid {
  display: grid;

  @for $col from 1 through 16 {
    &[grid-col="#{$col}"] {
      grid-template-columns: repeat($col, 1fr);
    }

    @media screen and (min-width: $mobile) and (max-width: $tablet) {
      &[grid-col-tablet="#{$col}"] {
        grid-template-columns: repeat($col, 1fr) !important;
      }
    }

    @media screen and (max-width: $mobile) {
      &[grid-col-mobile="#{$col}"] {
        grid-template-columns: repeat($col, 1fr) !important;
      }
    }
  }

  $gap: 5;
  @while $gap <= 100 {
    &[grid-gap="#{$gap}"] {
      gap: #{$gap}px;
    }

    $gap: $gap + 5;
  }

}


.GridItem {
  $span: 1;
  @while $span <= 15 {
    &[col-span="#{$span}"] {
      grid-column: span #{$span};
    }
    &[row-span="#{$span}"] {
      grid-row: span #{$span};
    }

    @media screen and (min-width: $mobile) and (max-width: $tablet) {
      &[col-span-tablet="#{$span}"] {
        grid-column: span #{$span} !important;
      }
      &[row-span-tablet="#{$span}"] {
        grid-row: span #{$span} !important;
      }
    }

    @media screen and (max-width: $mobile) {
      &[col-span-mobile="#{$span}"] {
        grid-column: span #{$span} !important;
      }
      &[row-span-mobile="#{$span}"] {
        grid-row: span #{$span} !important;
      }
    }

    $span: $span + 1;
  }
}