@import "../../../../assets/global_variables.scss";

.AppList {
  display: flex;
  flex-wrap: wrap;
  a {
    font-size: 20px;
    padding: 15px 40px;
    text-align: center;
    color: $brandColor3;
    ion-icon {
      font-size: 90px;
    }
  }


}