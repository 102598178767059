@import "../../../../assets/global_variables.scss";

.WhatWeDoCard {

  .IconContainer {
    color: $brandColor1;
  }

  h3 {
    font-size: 24px;
    font-family: "DM Serif Display";
    color: $brandColor1;
  }

  p {
    b {
      color: $brandColor5;
    }
  }
}