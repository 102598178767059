@import "./assets/global_variables.scss";

body {
  //background-color: $brandColor1;
  //color: #fff;
}

.header-font-1 {
  font-family: 'DM Serif Display';
}

.Main {
  min-height: calc(100vh - 100px);
}

.ContentMin {
  min-height: calc(100vh - 170px);
}

.other-page-container {
  padding-top: 70px;
  min-height: calc(100vh - 83px);
}

.static-link-1 {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

.long-text-body-main-ol {
 & > li {
    font-size: 25px;
    padding: 10px 0px;

    p,
    ul,ol li{
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1.6em;
    }

    h4 {
      font-size: 18px;
      margin-bottom: 5px;
    }
    
  }
}

.disabled-card-link {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-link {
  pointer-events: none;
}


@keyframes float {
  from {
    transform: translateY(100vh);
    opacity: 1;
  }
  to {
    transform: translateY(-300vh);
    opacity: 0;
  }
}

.simple-form {
  max-width: 500px;
}

.simple-para {
  max-width: 800px;
  margin: auto;
}

.blog-container {
  max-width: 800px !important;
}


.form-group {
  margin-bottom: 15px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  thead {
    th,
    td {
      text-align: center;
    }
  }

  td, th {
    border: 1px solid #444;
    padding: 10px 10px;
  }

  td.group-key {
    border: none;
    padding-top: 30px;
    font-weight: bold;
  }
}