@import "../global_variables.scss";
$transform : (uppercase, lowercase, capitalize);
$fontStyle : (italic, oblique);
$textDecoration : (underline, blink, overline, line-through);
$textAlign : (center, left, right, justify);

@each $transName in $transform {
  .text-transform-#{$transName} {
    text-transform: $transName;
  }
}

@each $alignName in $textAlign {
  .text-align-#{$alignName} {
    text-align: $alignName;
  }
  
  @media screen and (max-width: $mobile) {
    .text-align-mobile-#{$alignName} {
      text-align: $alignName !important;
    }
  }
  @media screen and (max-width: $tablet) {
    .text-align-tablet-#{$alignName} {
      text-align: $alignName !important;
    }
  }
}

@each $style in $fontStyle {
  .font-style-#{$style} {
    font-style: $style;
  }
}

@each $textDecor in $textDecoration {
  .text-decoration-#{$textDecor} {
    text-decoration: $textDecor;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}