@import "../../../assets/global_variables.scss";

.NavBar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
  background-color: #fff;
}

.MobileMenuClose,
.MobileMenuTrigger {
  border: none;
  background-color: transparent;
  font-size: 30px;
}

.MobileMenuClose {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
}

.MobileMenuClose,
.MobileMenuTrigger {
  display: none;
}

.Navigations {

  ul {

    display: flex;
    padding: 0px;

    li {
      display: block;

      a {
        padding: 10px 15px;
        font-size: 17px;
        text-decoration: none;
        color: #444;
        font-family: 'poppins';
        position: relative;

        &:global(.active)::after {
          background-color: #444;
          bottom: 0;
          content: "";
          display: block;
          height: 2px;
          left: 0;
          position: absolute;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  
  .MobileMenuClose,
  .MobileMenuTrigger {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .Navigations {
    position: fixed;
    z-index: 101;
    width: 100vw;
    height: 100vh;
    background-color: #000000eb;
    top: 0px;
    right: -100vw;
    margin-top: 0px;
    display: flex;
    align-items: center;
    transition: 0.3s;
  
    &.active {
      right: 0vw;
    }

    ul {
      display: block;
      width: 100%;
      li {
        display: block;
        text-align: center;
        a {
          font-size: 35px;
          padding: 15px 10px;
          display: inline-block;
          color: #fff;

          &:global(.active)::after {
            background-color: #fff;
          }
        }
      }
    }

  }


}