@import "../../../assets/global_variables.scss";

.HomeBanner {
  width: 100%;
  aspect-ratio: 4/1.7;
  min-height: 590px;
  background-size: cover;
  background-position: center center;
  background-color: $brandColor2;
  position: relative;

  .GraphGraphic {
    fill: $brandColor1;
  }

  * {
    z-index: 1;
  }

  .innerContainer {
    height: 100%;
  }

  .RightImg {
    width: 400px;
  }

  h1 {
    font-family: "poppins";
    color: #fff;
    font-size: 50px;
    line-height: 55px;
    margin-top: 10px;
  }

  h4 {
    color: #fff;
    font-size: 20px;
    font-weight: normal;
  }

  button {
    min-width: 151px;
  }

  @media screen and (max-width: $tablet) {
    aspect-ratio: 4/3;
    min-height: auto;
  }

  @media screen and (max-width: $mobile) {
    aspect-ratio: 1/1.4;

    h1 {
      font-size: 9vw;
      line-height: 1em;
    }

  }
}