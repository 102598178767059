.ChatContainer {
  border-radius: 30px;
  padding: 15px 20px;
  color: white;
  width: 272px;
  text-decoration: none;
  background: #444;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.04);
  }
  
  
  &.WhatsappChatInline {
    color: #25d366;
    background-color: #e8fff0;
  }
  
  &.EmailChatInline {
    color: #c71610;
    background: #ffeded;
  }
  
  &.GsmChat {
    color: #3e65cf;
    background: #e7edfd;
  }
}