@use "sass:map";
@import "../global_variables.scss";

$brandColors : (brand-color1: $brandColor1, brand-color2: $brandColor2, brand-color3: $brandColor3, brand-color4: $brandColor4);

$greyScale : (inherit: inherit, transparent: transparent, 000: #000, 111: #111, 222: #222, 333: #333, 444: #444, 555: #555, 666: #666, 777: #777, 888: #888, 999: #999, aaa: #aaa, bbb: #bbb, ccc: #ccc, ddd: #ddd, eee: #eee, ed:#ededed, fff: #fff, fa: #fafafa, f5f8:#F5F8FF);

$allColors : map.merge($brandColors, $greyScale);

@each $colorName, $colorValue in $allColors {
  .color-#{$colorName} {
    color: $colorValue;
  }

  .bg-color-#{$colorName} {
    background-color: $colorValue;
  }

  
  .border-color-#{$colorName} {
    border-color: $colorValue;
  }
}