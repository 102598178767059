.ServicesMiniCard {
  background-color: #fcfcfc;
  padding: 57px 34px 25px 34px;
  border-radius: 10px;

  :global {
    .IconContainer {
      aspect-ratio: 1;
      width: 60px;
      background-color: #45b26b;
      color: #fff;
      border-radius: 50%;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .CardBody {
      margin-top: 60px;

      h4 {
        font-size: 24px;
        line-height: 1em;
        margin-bottom: 15px;
        color: #222;
        font-family: "poppins";
      }

      p {
        color: #777;
      }
    }


    button {
      border-radius: 4px;
      background-color: #5ca1e1;
      border: none;
      color: #fff;
      text-align: center;
      font-size: 32px;
      padding: 16px;
      width: 220px;
      transition: all 0.5s;
      cursor: pointer;
      margin: 36px;
      box-shadow: 0 10px 20px -8px rgba(0, 0, 0, .7);
    }

    button {
      cursor: pointer;
      display: inline-block;
      position: relative;
      transition: 0.5s;
    }

    button:after {
      content: '»';
      position: absolute;
      opacity: 0;
      top: 14px;
      right: -20px;
      transition: 0.5s;
    }

    button:hover {
      padding-right: 24px;
      padding-left: 8px;
    }

    button:hover:after {
      opacity: 1;
      right: 10px;
    }

  }
}