@import "../global_variables.scss";
$mobile: 768px;

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 65, 70, 75, 80, 90, 100, 120, 150, 200); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {


  .left-#{$space} {
    left: #{$space}px;
  }

  .right-#{$space} {
    right: #{$space}px;
  }

  .top-#{$space} {
    top: #{$space}px;
  }

  .bottom-#{$space} {
    bottom: #{$space}px;
  }

  .w-#{$space} {
    width: #{$space}+'%';
  }

  .h-#{$space} {
    height: #{$space}+'%';
  }

  .w-px-#{$space} {
    width: #{$space}px;
  }

  .flex-basis-#{$space} {
    flex-basis: #{$space}+'%';
  }

  .m-#{$space} {
    margin: #{$space}px;
  }

  .p-#{$space} {
    padding: #{$space}px;
  }

  .px-#{$space} {
    padding-left: #{$space}px;
    padding-right: #{$space}px;
  }

  .py-#{$space} {
    padding-top: #{$space}px;
    padding-bottom: #{$space}px;
  }

  .border-width-#{$space} {
    border-width: #{$space}px;
  }

  .border-radius-px-#{$space} {
    border-radius: #{$space}px;
  }

  .border-radius-percentage-#{$space} {
    border-radius: #{$space}+'%';
  }

  .font-size-#{$space} {
    font-size: #{$space}px;
  }


  @media screen and (max-width: $tablet) and (min-width: calc($mobile + 1px)) {

    .flex-basis-tablet-#{$space} {
      flex-basis: #{$space}+'%' !important;
    }

    .px-tablet-#{$space} {
      padding-left: #{$space}px !important;
      padding-right: #{$space}px !important;
    }

    .py-tablet-#{$space} {
      padding-top: #{$space}px !important;
      padding-bottom: #{$space}px !important;
    }

    .font-size-tablet-#{$space} {
      font-size: #{$space}px !important;
    }

    .w-tablet-#{$space} {
      width: #{$space}+'%';
    }

  }


  @media screen and (max-width: $mobile) {

    .flex-basis-mobile-#{$space} {
      flex-basis: #{$space}+'%' !important;
    }

    .px-mobile-#{$space} {
      padding-left: #{$space}px !important;
      padding-right: #{$space}px !important;
    }

    .py-mobile-#{$space} {
      padding-top: #{$space}px !important;
      padding-bottom: #{$space}px !important;
    }

    .font-size-mobile-#{$space} {
      font-size: #{$space}px !important;
    }

    .w-mobile-#{$space} {
      width: #{$space}+'%';
    }

  }



  @each $side in $sides {

    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px;
    }

    @media screen and (max-width: $mobile) {
      .m#{str-slice($side, 0, 1)}-mobile-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }

      .p#{str-slice($side, 0, 1)}-mobile-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
}

$borderStyles : (solid, dashed);

@each $style in $borderStyles {
  .border-style-#{$style} {
    border-style: $style;
  }
}