.AboutImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  
  img {
    max-width: 400px;
    width: 100%;
  }
}