.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;

  &:global(.long-para-content) {
    max-width: 800px !important;
  }
  &:global(.fluid) {
    max-width: none !important;
  }

  @media screen and (max-width: 1340px) {
    padding-left: 30px;
    padding-right: 30px;
  }


  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

}