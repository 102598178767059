@import "../../../assets/global_variables.scss";

.Footer {
  .container {
    @media screen and  (max-width: $mobile) {
      justify-content: center;
    }
  }
  
}


.Links {
  padding: 0px;
  margin: 0px;
  li {
    display: inline-block;
    a {
      padding: 10px 10px;
    }
  }
}