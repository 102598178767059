@import "../../../assets/global_variables.scss";

.AppListContainer {
  background-color: #fff;
  width: 300px;
  position: absolute;
  right: 0px;
  top: 30px;
  padding: 30px 15px;
  border-radius: 15px;
  z-index: 3;
  
  grid-template-columns: repeat(3, 1fr);
  
  display: none;
  
  &.Active {
    display: grid;
    gap: 20px;
  }
  
  a {
    color: #222;
    text-decoration: none;
    text-align: center;
    ion-icon {
      font-size: 33px;
    }

    h4 {
      font-size: 13px;
      font-weight: normal;
    }
  }
}

.BackDrop {
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  top: 0px;
  left: 0px;
  display: none;

  &.Active{
    display: block;
  }
}

@media screen and (max-width: $mobile) {
  .AppListContainer {
    position: fixed;
    top: 80px;
    width: calc(100vw - 20px);
    margin: 0px 10px;
  }
}