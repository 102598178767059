.BlogCard {
  background-color: #fff;
  padding: 20px;

  :global(.title) {
    font-size: 22px;
    font-family: "poppins";
    font-weight: 600;
  }

  :global(.text_content) {
    white-space: pre-wrap;
  }
}