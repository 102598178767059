.InlineLoader {
  display: inline-flex;
  align-items: center;

  .Text {
    margin-left: 10px;
    font-size: 17px;
  }


  :global {

    .lds-dual-ring {
      display: inline-block;
      width: 30px;
      aspect-ratio: 1;
    }

    .lds-dual-ring:after {
      content: " ";
      display: block;
      width: 20px;
      aspect-ratio: 1;
      margin: 0px;
      border-radius: 50%;
      border: 4px solid #000;
      border-color: #000 transparent #000 transparent;
      animation: roateAnim 1.2s linear infinite;
    }

  }

  &[theme-type="light"] {
    :global {
      .lds-dual-ring:after {
        border-color: #fff transparent #fff transparent;
      }
    }
  }
}