@import "../../../assets/global_variables.scss";

.Button {
  &[theme-type='theme1'] {
    padding: 14px 24px;
    font-size: 17px;
    background-color: $brandColor2;
    color: #000;
    border: none;
    border-radius: 3px;
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.05);
    }
    
  }
  &[theme-type='theme2'] {
    padding: 14px 24px;
    font-size: 17px;
    background-color: #3772ff;
    color: #fff;
    border: none;
    border-radius: 3px;
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.05);
    }
    
  }

  &[theme-type='theme3'] {
    padding: 14px 24px;
    font-size: 17px;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 3px;
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.05);
    }
    
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }
}

.formGroup {
  margin-bottom: 15px;
}

:global {
  label {
    display: block;
    line-height: 2em;
    font-size: 17px;
  }

  button,
  select,
  input:not([type="file"]) {
    height: 40px;
    padding-left: 15px;
    border-radius: 10px;
    width: 100%;
    border: 1px solid #444;
    background-color: #fff;
  }

  button {
    width: initial;
    padding: 5px 20px;
    height: unset;
  }
}