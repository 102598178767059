@import "../../../../assets/global_variables.scss";

.LeadersCard {
  // max-width: 500px;

  background-color: #fff;
  padding: 10px 10px 20px 10px;
  border-radius: 3px;

  .ProfilePictureContainer {
    flex-shrink: 0;
    margin: auto;

    img {
      width: 100%;
      // border-radius: 50%;
    }
  }

  .TextSection {
    padding-left: 15px;
    text-align: center;
    margin-top: 10px;

    h3 {
      line-height: 1em;
      font-family: "poppins";
      font-size: 17px;
      color: $brandColor1;
    }

    h4 {
      font-size: 14px;
      color: $brandColor3;
      font-weight: 400;
    }

    p {
      margin-top: 2px;
      line-height: 1.4em;
      font-size: 16px;
      color: $brandColor3
    }
  }
}