@import "../global_variables.scss";

$justifyContent : (space-between, end, start, flex-end, flex-start, center, space-between, space-around, space-evenly);

@each $justify in $justifyContent {
  .justify-content-#{$justify} {
    justify-content: $justify;
  }

  .justify-items-#{$justify} {
    justify-items: $justify;
  }
}

$alignItems : (center, flex-start, flex-end, stretch);

@each $align in $alignItems {
  .align-items-#{$align} {
    align-items: $align;
  }

  .align-content-#{$align} {
    align-content: $align;
  }
}

$flexDirections : (row, row-reverse, column, column-reverse, initial, inherit);

@each $dir in $flexDirections {
  .flex-direction-#{$dir} {
    flex-direction: $dir;
  }
}


$flexWrap : (nowrap, wrap, wrap-reverse);

@each $wrap in $flexWrap {
  .flex-wrap-#{$wrap} {
    flex-wrap: $wrap;
  }
}

$flexNumbers : (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);

@each $flexSize in $flexNumbers {
  .flex-grow-#{$flexSize} {
    flex-grow: $flexSize;
  }

  .flex-shring-#{$flexSize} {
    flex-shrink: $flexSize;
  }

  .flex-order-#{$flexSize} {
    order: $flexSize;
  }

  @media screen and (max-width: $tablet) {
    .flex-grow-tablet-#{$flexSize} {
      flex-grow: $flexSize;
    }

    .flex-shring-tablet-#{$flexSize} {
      flex-shrink: $flexSize;
    }

    .flex-order-tablet-#{$flexSize} {
      order: $flexSize;
    }
  }

  @media screen and (max-width: $mobile) {
    .flex-grow-mobile-#{$flexSize} {
      flex-grow: $flexSize;
    }

    .flex-shring-mobile-#{$flexSize} {
      flex-shrink: $flexSize;
    }

    .flex-order-mobile-#{$flexSize} {
      order: $flexSize;
    }
  }
}